import React, { useEffect, useState } from 'react'
import './App.css'
import WebFont from 'webfontloader'
import TimeBlock from './components/TimeBlock'
import Section from './components/Section'
import SimpleBlock from './components/SimpleBlock'
import Footer from './components/Footer'
import Header from './components/Header'
import exportAsPDF from './utils/export'
import { Language } from './model'

function App() {
  const [lang, setLang] = useState<Language>(Language.DE)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto Condensed', 'Montserrat: 100,200,300,400,500,600,700,800,900'],
      },
    })
  }, [])

  return (
    <div className="container">
      <div id="viewport-to-print">
        <div className="flex justify-end gap-4 pr-4 pt-2 md:p-4">
          <button
            className={lang === Language.EN ? 'text-gray-500' : 'underline'}
            onClick={() => setLang(Language.EN)}
          >
            EN
          </button>
          <button
            className={lang === Language.DE ? 'text-gray-500' : 'underline'}
            onClick={() => setLang(Language.DE)}
          >
            DE
          </button>
        </div>
        <Header lang={lang} />
        <Section title={lang === Language.EN ? 'Work experience' : 'Berufserfahrung'}>
          <TimeBlock
            timePeriod={'05/2022 – ' + (lang === Language.EN ? 'present' : 'heute')}
            position={
              lang === Language.EN ? 'Full Stack Developer' : 'Full-Stack-Softwareentwickler'
            }
            company={'FDK GmbH (' + (lang === Language.EN ? 'Remote' : 'Remote') + ')'}
            bulletPoints={
              lang === Language.EN
                ? [
                    'Backend development in TypeScript and GraphQL',
                    'Frontend development in React and AngularJS using TypeScript',
                    'Creation of unit tests and refactoring of existing applications',
                  ]
                : [
                    'Backend-Entwicklung in TypeScript und GraphQL',
                    'Frontend-Entwicklung in React and AngularJS mit TypeScript',
                    'Erstellung von UnitTests und Refactoring bestehender Applikationen',
                  ]
            }
          />
          <TimeBlock
            timePeriod={'08/2017 – 04/2022'}
            position={
              lang === Language.EN ? 'Full Stack Developer' : 'Full-Stack-Softwareentwickler'
            }
            company={
              'FOCUS Online Group GmbH (' + (lang === Language.EN ? 'Munich' : 'München') + ')'
            }
            bulletPoints={
              lang === Language.EN
                ? [
                    'Maintenance and further development of a website with 30 million unique users per month',
                    'Planning of IT projects from the first development phase to roll-out',
                    'Backend development in Java EE and Spring Boot (JPA, JSF, JSP, PrimeFaces, GlassFish and Tomcat Server)',
                    'Frontend development in HTML5, CSS, JavaScript and jQuery',
                    'Development of various widgets and other web components with React and AngularJS',
                    'Consulting and support for customers in the implementation and delivery of projects',
                    'A/B testing with Optimizely (implementation of extensions in Vanilla JS)',
                    'Creation of unit tests and refactoring of existing applications',
                    'Analysis of functional and technical requirements',
                    'Migration of old applications to AWS',
                  ]
                : [
                    'Pflege und Weiterentwicklung einer Website mit 30 Millionen unique Usern im Monat',
                    'Planung von IT-Projekten von der ersten Entwicklungsphase bis zum Roll-Out',
                    'Backend-Entwicklung in Java EE und Spring Boot (JPA, JSF, JSP, PrimeFaces, GlassFish und Tomcat Server)',
                    'Frontend-Entwicklung in HTML5, CSS, JavaScript und jQuery',
                    'Entwicklung von verschiedenen Widgets und anderen Web-Components mit React und AngularJS',
                    'Beratung und Unterstützung von Kunden bei Umsetzung und Auslieferung von Projekten',
                    'A/B-Testing mit Optimizely (Implementierung von Erweiterungen in Vanilla JS)',
                    'Erstellung von UnitTests und Refactoring bestehender Applikationen',
                    'Analyse von funktionalen und technischen Anforderungen',
                    'Migration von alten Applikationen zu AWS',
                  ]
            }
          />
          <TimeBlock
            timePeriod="10/2016 – 07/2017"
            position={lang === Language.EN ? 'Developer' : 'Softwareentwickler'}
            company={
              'Bertrandt Ingenieurbüro GmbH (' + (lang === Language.EN ? 'Munich' : 'München') + ')'
            }
            bulletPoints={
              lang === Language.EN
                ? [
                    'Design, development and protection of Java application software and Java backend software',
                    'Programming of software modules based on requirements',
                    'Execution of performance analyzes and optimizations as well as creation of unit tests',
                    'Analysis of existing software and restructuring of it while maintaining the observed program behavior (refactoring)',
                    'Research, evaluation and assessment of new technologies with regard to future use',
                  ]
                : [
                    'Entwurf, Entwicklung und Absicherung von Java-Applikations-Software und Java-Backend-Software',
                    'Programmierung von Software-Modulen auf Basis von Anforderungen',
                    'Durchführung von Performance-Analysen und Optimierungen sowie Erstellung von UnitTests',
                    'Analyse bereits existierender Software und Umstrukturierung dieser unter Beibehaltung des beobachteten Programmverhaltens (Refactoring)',
                    'Recherche, Evaluation und Bewertung neuer Technologien hinsichtlich zukünftiger Verwendung',
                  ]
            }
          />
          <TimeBlock
            timePeriod="07/2015 – 09/2016"
            position={lang === Language.EN ? 'Developer' : 'Softwareentwickler'}
            company="Ingenious Technologies AG (Berlin)"
            bulletPoints={
              lang === Language.EN
                ? [
                    'Development and querying databases with PostgreSQL',
                    'Programming with Java and Python',
                    'Development of ETL processes with Pentaho Data Integration (Kettle)',
                    'Frontend development with AngularJS',
                  ]
                : [
                    'Entwicklung und Abfrage Datenbanken mit PostgreSQL',
                    'Programmierung mit Java und Python',
                    'Entwicklung ETL Prozessen mit Pentaho Data Integration (Kettle)',
                    'Frontendentwicklung mit AngularJS',
                  ]
            }
          />
          <TimeBlock
            timePeriod="05/2013 – 09/2014"
            position={lang === Language.EN ? 'Developer' : 'Softwareentwickler'}
            company={
              'Bank Innovation System LLC (' + (lang === Language.EN ? 'Y' : 'J') + 'ekaterinburg)'
            }
            bulletPoints={
              lang === Language.EN
                ? [
                    'Development of control systems for the inspection and sorting machines for individual banknotes',
                    'Programming with C, C ++, Win32 API',
                    'Update and maintenance of existing systems',
                  ]
                : [
                    'Entwicklung Control Systems für die Inspektions- und Sortiermaschinen für einzelne Banknoten',
                    'Programmierung mit C, C++, Win32 API',
                    'Aktualisierung und Pflege bestehender Systeme',
                  ]
            }
          />
          <TimeBlock
            timePeriod="07/2012 – 04/2013"
            position={lang === Language.EN ? 'Development Engineer' : 'Entwicklungsingenieur'}
            company={'OKB Novator (' + (lang === Language.EN ? 'Y' : 'J') + 'ekaterinburg)'}
            bulletPoints={
              lang === Language.EN
                ? [
                    'Modeling and simulation programming with Fortran',
                    'Programming GUI application with C#',
                  ]
                : [
                    'Modellierung und Simulation Programmierung mit Fortran',
                    'Programmierung GUI Application mit C#',
                  ]
            }
          />
        </Section>
        <Section title={lang === Language.EN ? 'Education' : 'Bildungsweg'}>
          <TimeBlock
            timePeriod="09/2006 – 06/2012"
            position={lang === Language.EN ? 'Master of Mechanics' : 'Magister der Mechanik'}
            company={
              lang === Language.EN
                ? 'Ural Federal University (Yekaterinburg, Russia)'
                : 'Staatlichen Universität des Urals (Jekaterinburg, Russland)'
            }
            bulletPoints={
              lang === Language.EN
                ? ['Course: Mechanics, Applied Mathematics', 'Subject: Theoretical Mechanics']
                : [
                    'Studiengang: Mechanik, angewandte Mathematik',
                    'Fachrichtung: Theoretische Mechanik',
                  ]
            }
          />
          {lang === Language.EN ? null : (
            <TimeBlock
              timePeriod="09/1996 – 06/2006"
              position="Abitur"
              company="Gymnasium (Komsomolsk am Amur, Russland)"
              bulletPoints={[]}
            />
          )}
        </Section>
        <Section title={lang === Language.EN ? 'Skills' : 'Weitere Kenntnisse'}>
          <SimpleBlock
            title={lang === Language.EN ? 'Languages' : 'Sprachen:'}
            bulletPoints={
              lang === Language.EN
                ? ['German - fluent', 'English - fluent', 'Russian - native language']
                : ['Deutsch – fließend', 'Englisch – fließend', 'Russisch – Muttersprache']
            }
          />
          <SimpleBlock
            title="Software:"
            bulletPoints={[
              'IDEs: IntellijIDEA, PyCharm, Visual Studio Code, NetBeans, Eclipse, MS Visual Studio, Xcode, Android Studio',
              (lang === Language.EN ? 'CMS' : 'Content-Management-Systeme') +
                ': WordPress, Escenic',
            ]}
          />
          <SimpleBlock
            title={'IT' + (lang === Language.EN ? ' knowledge' : '-Kenntnisse')}
            bulletPoints={[
              (lang === Language.EN ? 'Programming languages' : 'Programmiersprachen') +
                ': Java, C, C++, C#, Python, JavaScript, TypeScript, CSS, HTML5, Fortran, Swift',
              (lang === Language.EN ? 'Libraries' : 'Biblioteken') +
                ' & Frameworks: React, AngularJS, jQuery, Spring Boot',
              (lang === Language.EN ? 'Databases' : 'Datenbanken') +
                ': MySQL, PostgreSQL, DynamoDB, Aurora',
              'Cloud: AWS, VPC, EC2, S3, SES, SQS, Lambda',
              'VCS: Git, SVN',
            ]}
          />
          <SimpleBlock
            title="SEO & Analytics"
            bulletPoints={[
              'Google Search Console, Google Analytics, Google Data Studio',
              'Screaming Frog SEO Spider',
            ]}
          />
          <SimpleBlock
            title={lang === Language.EN ? 'Miscellaneous' : 'Sonstiges'}
            bulletPoints={['JIRA, BitBucket, Jenkins, GitLab']}
          />
        </Section>
        <Footer lang={lang} />
      </div>
      <div className="justify-center mb-20 hidden md:flex">
        <button
          className="bg-red-500 text-white text-lg rounded-lg px-8 py-2 hover:shadow-lg"
          onClick={() => exportAsPDF('viewport-to-print')}
        >
          {'Export ' + (lang === Language.EN ? 'as' : 'als') + ' PDF'}
        </button>
      </div>
    </div>
  )
}

export default App
