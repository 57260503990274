import React from 'react'
import photo from '../assets/photo.jpg'

import SocialBar from './SocialBar'
import { Language } from '../model'

type HeaderProps = {
  lang: Language
}

function Header(props: HeaderProps) {
  return (
    <div className="flex mb-10">
      <div className="hidden md:flex justify-end items-center w-1/3 p-8">
        <img className="w-full md:w-11/12" src={photo} alt="Vladimir Koch" />
      </div>
      <div className="flex flex-col justify-between w-full md:w-2/3 p-4 md:p-8">
        <div>
          <h1 className="text-4xl md:text-5xl text-gray-900 font-black md:font-extrabold mb-2 md:mb-4">
            Vladimir Koch
          </h1>
          <p className="text-sm md:text-base text-gray-400 mb-8">
            {props.lang === Language.EN
              ? 'Born on July 2nd, 1989 in Komsomolsk on Amur, Russia, married, has two children'
              : 'Geb. am 02. Juli 1989 in Komsomolsk am Amur, Russland, verheiratet, hat zwei Kinder'}
          </p>
        </div>
        <blockquote className="text-sm md:text-base italic text-left md:text-center leading-5 md:leading-8 mb-8">
          {props.lang === Language.EN
            ? '"I am a Java/TypeScript full stack developer with more than 10 years of experience in software development. I am excited to bring my passion and commitment to help your business grow and ensure your success."'
            : '"Ich bin ein Full-Stack-Softwareentwickler mit Fokus auf Java/TypeScript und mehr als 10 Jahren Erfahrung in der Softwareentwicklung. Ich freue mich darauf, meine Leidenschaft und mein Engagement einzubringen, um Ihr Unternehmen wachsen zu lassen und Ihren Erfolg zu sichern."'}
        </blockquote>
        <div className="flex justify-center">
          <SocialBar />
        </div>
      </div>
    </div>
  )
}

export default Header
