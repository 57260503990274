import React from "react";

type SimpleBlockProps = {
    title: string;
    bulletPoints: string[];
}

function SimpleBlock(props: SimpleBlockProps) {
    return (
        <div className='px-4 md:px-8 mb-12'>
            <h3 className='text-gray-900 font-bold mb-3.5 text-center md:text-left'>{props.title}</h3>
            <ul className='text-sm md:text-base'>
                {
                    props.bulletPoints.map((bulletPoint, index) => (
                        <li key={index} className='mb-3.5'>{bulletPoint}</li>
                    ))
                }
            </ul>
        </div>
    )
}

export default SimpleBlock;