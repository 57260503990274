import React from "react";

type TimeBlockProps = {
    timePeriod: string;
    position: string;
    company: string;
    bulletPoints: string[];
}

function TimeBlock(props: TimeBlockProps) {

    return (
        <div className='px-8 mb-12'>
            <p className='text-red-600 md:mb-2 text-sm md:text-base text-center md:text-left'>{props.timePeriod}</p>
            <p className='text-gray-900 text-lg font-bold text-center md:text-left'>{props.position}</p>
            <p className='text-gray-500 mb-5 md:mb-3.5 text-xs md:text-base text-center md:text-left'>{props.company}</p>
            <ul className='list-disc list-outside text-sm md:text-base'>
                {
                    props.bulletPoints.map((bulletPoint, index) => (
                        <li key={index} className='mb-3.5'>{bulletPoint}</li>
                    ))
                }
            </ul>
        </div>
    )
}

export default TimeBlock;