import React from "react";
import linkedin from "../assets/linkedin.svg";
import github from "../assets/github.svg";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";

function SocialBar() {

    const rel = 'noopener noreferrer nofollow';
    const target = '_blank';
    const size = 45;

    return (
        <div className='flex items-center gap-12'>
            <a href='https://www.linkedin.com/in/vladimir-koch-515347115/' target={target} rel={rel}>
                <img width={size} height={size} src={linkedin} alt='Vladimir Koch | LinkedIn'/>
            </a>
            <a href='https://github.com/vladimirkoch' target={target} rel={rel}>
                <img width={size} height={size} src={github} alt='Vladimir Koch | GitHub'/>
            </a>
            <a href='https://www.facebook.com/profile.php?id=100009316384230' target={target} rel={rel}>
                <img width={size} height={size} src={facebook} alt='Vladimir Koch | Facebook'/>
            </a>
            <a href='https://www.instagram.com/vladimir_koch/' target={target} rel={rel}>
                <img width={size} height={size} src={instagram} alt='Vladimir Koch | Instagram'/>
            </a>
        </div>
    )
}

export default SocialBar;