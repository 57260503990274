import React from "react";
import {Language} from "../model";

type FooterProps = {
    lang: Language;
}

function Footer(props: FooterProps) {
    return (
        <div className='flex py-10 md:py-20 px-4 md:px-8 mb-10 text-sm md:text-base'>
            <div
                className='w-1/2 text-left'>{props.lang === Language.EN ? 'Munich' : 'München'}, {new Date().toLocaleDateString('de')}</div>
            <div className='w-1/2 text-right'>Vladimir Koch</div>
        </div>
    )
}

export default Footer;