import React, {PropsWithChildren} from "react";

type SectionProps = PropsWithChildren<{ title: string }>

function Section(props: SectionProps) {
    return (
        <section className='w-full flex flex-wrap mb-10 md:mb-0'>
            <div className='w-full md:w-1/3 md:pr-8 mb-8 md:mb-4'>
                <h2 className='uppercase font-semibold text-xl text-gray-700 text-center md:text-right'>{props.title}</h2>
            </div>
            <div className='w-full md:w-2/3 md:border-l md:border-gray-500'>
                {props.children}
            </div>
        </section>
    )
}

export default Section;