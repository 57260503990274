import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

export default function exportAsPDF(elementId: string): void {
    const input = document.getElementById(elementId);
    if (input) {
        html2canvas(input).then((canvas) => {
            canvas.getContext('2d');
            const HTML_Width = canvas.width;
            const HTML_Height = canvas.height;
            const top_left_margin = 0;
            const PDF_Width = HTML_Width + (top_left_margin * 2);
            const PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
            const canvas_image_width = HTML_Width;
            const canvas_image_height = HTML_Height;

            const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
            console.log(canvas.height + "  " + canvas.width);


            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


            for (let i = 1; i <= totalPDFPages; i++) {
                pdf.addPage([PDF_Width, PDF_Height]);
                let margin = -(PDF_Height * i) + (top_left_margin * 4);
                if (i > 1) {
                    margin = margin + i * 8;
                }
                console.log(top_left_margin);
                console.log(top_left_margin);
                console.log(-(PDF_Height * i) + (top_left_margin * 4));
                pdf.addImage(imgData, 'JPG', top_left_margin, margin, canvas_image_width, canvas_image_height);

            }

            pdf.save('Vladimir_Koch_CV.pdf');
        });
    }
}